import React from 'react'

import Gallery from '../../components/common/gallery';

const HugoUnite = () => (
  <Gallery
    imageLinks={
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17].map((number) => (
        `https://s3.amazonaws.com/valentina-site/portfolio_images/hugo_unite/HBV${number}.jpg`
      ))
    }
    navigationColor='#c3c6cc'
    fromText='Portfolio'
    fromLink='/portfolio'
  />
)

export default HugoUnite